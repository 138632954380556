export const ADMINS = ['Admin'];

export const CONSTANT = {
  MIN_PWD_LENGTH: 5,
  MAX_PWD_LENGTH: 25,
  MIN_NAME_LENGTH: 3,
  MAX_NAME_LENGTH: 25,
  MAX_TAX: 28,
  FILE_SIZE_VALIDATION: 10, // in MB
};

export const REGEX = {
  PASSWORD_REGEX: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{5,25})$/,
  EMAIL_REGEX: /^[\w-.+]+@([\w-]+\.)+[a-zA-Z-]{2,4}$/,
  ALPHA_NUMERIC_REGEX: /^[a-zA-Z0-9_ ]*$/,
  NUMBER_REGEX: /^[0-9]*$/
};

export const VALIDATIONS = {
  EMAIL_REQUIRED: 'Please enter email address',
  PASSWORD_REQUIRED: 'Please enter password',
  OLD_PASSWORD_REQUIRED: 'Please enter current password',
  NEW_PASSWORD_REQUIRED: 'Please enter new password',
  CONFIRM_PASSWORD_REQUIRED: 'Please enter confirm password',
  PASSWORD_NOT_MATCH: 'Password does not match, Please check',
  SAME_PASSWORD: 'Current password and New Password must be different',
  INVALID_EMAIL: 'Please enter a valid email address',
  INVALID_PASSWORD: 'Please enter valid password',
  INVALID_PASSWORD_FORMAT:
    'Password must contain at least one number, one uppercase and one special character',
  MIN_PASSWORD: `Min ${CONSTANT.MIN_PWD_LENGTH} characters `,
  MAX_PASSWORD: `Max ${CONSTANT.MAX_PWD_LENGTH} characters `,
  MIN_CATEGORY_NAME: `Name must be at least ${CONSTANT.MIN_NAME_LENGTH} characters`,
  MIN_PLAN_NAME: `Name must be at least ${CONSTANT.MIN_NAME_LENGTH} characters`,
  MIN_PLAN_PRICE: `Price must be greater than 0`,
  MIN_PLAN_TAX: `Tax percentage must be greater than 0`,
  MAX_PLAN_TAX: `Tax percentage must be lower than ${CONSTANT.MAX_TAX}`,
  MAX_CATEGORY_NAME: `Name must be less than ${CONSTANT.MAX_NAME_LENGTH} characters `,
  MAX_PLAN_NAME: `Name must be less than ${CONSTANT.MAX_NAME_LENGTH} characters `,
  MIN_DEPARTMENT: `Department must be at least ${CONSTANT.MIN_NAME_LENGTH} characters`,
  MAX_DEPARTMENT: `Department must be at least ${CONSTANT.MAX_NAME_LENGTH} characters`,
  DEPARTMENT_REQUIRED: `Please enter department name`,
  PROJECT_CATEGORY_REQUIRED: `Please enter project category name`,
  TEMPLATE_FILE_NAME_REQUIRED: `Please enter template file name`,
  TEMPLATE_FILE_REQUIRED: `Please select file`,
  INVALID_CATEGORY: 'Please enter valid category name',
  INVALID_NAME: 'Please enter valid name',
  PLAN_REQUIRED: `Please enter plan name`,
  PLAN_TYPE_REQUIRED: 'Please select plan type',
  CURRENCY_REQUIRED: 'Please select currency',
  FEATURES_REQUIRED: 'Please select at least one feature',
  PRICE_REQUIRED: 'Please enter price',
  TAX_PERCENTAGE_REQUIRED: 'Please enter tax percentage',
  INVALID_FILE_SIZE: `File must be shorter than ${CONSTANT.FILE_SIZE_VALIDATION} MB`,
};

export const SUBADMINS = ['Sub Admin'];
export const rolesNumberToName = {
  1: 'super_admin'
};
export const roleNameToNumber = {
  super_admin: '1'
};
export const moduleNumberToName = {
  1: 'Project Category',
  2: 'Production House Admin',
  3: 'Plan Management',
  4: 'Transaction History',
  5: 'Reports'
};
export const moduleNameToNumber = {
  'Project Category': '1',
  'Production House Admin': '2',
  'Plan Management': '3',
  'Transaction History': '4',
  Reports: '5',
  Inquiry: '6',
  'Document Category': '7',
  'Role Management': '8',
  Templates: '9',
  Departments: '10'
};
export const generalStatus = {
  Inactive: '1',
  Active: '2'
};
export const generalStatusName = {
  1: 'Inactive',
  2: 'Active'
};

export const paymentStatus = {
  Paid: '1',
  Failed: '2',
  Pending: '3'
};

export const paymentStatusName = {
  1: 'Paid',
  2: 'Failed',
  3: 'Pending'
};

export const subscriptionPlanType = {
  Free: '1',
  Paid: '2',
  Enterprise: '3'
};
export const subscriptionPlanTypeName = {
  1: 'Free',
  2: 'Paid',
  3: 'Enterprise'
};

export const planFeatures = [
  { name: 'Onboard', status: false },
  { name: 'KYC', status: false },
  { name: 'Smart Contracts', status: false },
  { name: 'Crew Database', status: false },
  { name: 'Price History', status: false },
  { name: 'Manage', status: false },
  { name: 'Payroll', status: false },
  { name: 'Data, AI & Insights', status: false }
];

export const currency = ['INR', 'USD'];

export const productionHouseUserRoleNumberToName = {
  1: 'Admin',
  2: 'C-Suite',
  3: 'Head - Accounts & Finance',
  4: 'Associate - Accounts & Finance',
  5: 'Auditor',
  6: 'Legal',
  7: 'Head of Production',
  8: 'Producer',
  9: 'Production',
  10: 'Direction'
};

export const moduleTypeNumberToName = {
  1: 'Project',
  2: 'Insurance List',
  3: 'Templates',
  4: 'Talent Database',
  5: 'Insight',
  6: 'Time Cards',
  7: 'Crew Members',
  8: 'Vendors',
  9: 'Invoices',
  10: 'Contracts',
  11: 'Payments',
  12: 'Accounting Integrations & Features',
  13: 'Project Document'
};
