import { object, string } from 'yup';
import { CONSTANT, VALIDATIONS } from '../config/constant';

const validationSchema = object({
  name: string()
    .min(CONSTANT.MIN_NAME_LENGTH, VALIDATIONS.MIN_DEPARTMENT)
    .max(CONSTANT.MAX_NAME_LENGTH, VALIDATIONS.MAX_DEPARTMENT)
    .required(VALIDATIONS.DEPARTMENT_REQUIRED)
    .trim()
});

export const initialValues = {
  name: ''
};
export default validationSchema;
