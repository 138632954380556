import { mixed, object, string } from 'yup';
import { CONSTANT, REGEX, VALIDATIONS } from '../config/constant';

const validationSchema = object({
  title: string()
    .min(CONSTANT.MIN_NAME_LENGTH, VALIDATIONS.MIN_CATEGORY_NAME)
    .max(CONSTANT.MAX_NAME_LENGTH, VALIDATIONS.MAX_CATEGORY_NAME)
    .required(VALIDATIONS.TEMPLATE_FILE_NAME_REQUIRED)
    .matches(REGEX.ALPHA_NUMERIC_REGEX, VALIDATIONS.INVALID_NAME)
    .trim(),
  file: mixed().required(VALIDATIONS.TEMPLATE_FILE_REQUIRED)
    .test('FileSizeValidation',
      VALIDATIONS.INVALID_FILE_SIZE, (value) => {
          return !value || (value && (Number((value.size / (1024*1024)).toFixed(2)) < CONSTANT.FILE_SIZE_VALIDATION  ))
      })
});

export const initialValues = {
  title: '',
  file: null
};
export default validationSchema;
