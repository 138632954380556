const DOCUMENT = 'DEPARTMENTS_';

const actions = {
  LOAD_FROM_API: DOCUMENT + 'LOAD_FROM_API',
  LOAD_FROM_API_SUCCESS: DOCUMENT + 'LOAD_FROM_API_SUCCESS',
  LOAD_FROM_API_ERROR: DOCUMENT + 'LOAD_FROM_API_ERROR',

  SAVE_INTO_API: DOCUMENT + 'SAVE_INTO_API',
  SAVE_INTO_API_ERROR: DOCUMENT + 'SAVE_INTO_API_ERROR',

  TOGGLE_API_HANDLE_MODAL: DOCUMENT + 'TOGGLE_API_HANDLE_MODAL',
  TOGGLE_LOADING: DOCUMENT + 'TOGGLE_LOADING',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadFromApi: (data) => {
    return { type: actions.LOAD_FROM_API, data };
  },

  loadFromApiSuccess: (data) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: { data }
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: { error }
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_API_HANDLE_MODAL,
    payload: { data }
  })
};
export default actions;
