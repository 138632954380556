import actions from './actions';
import { initialValues } from '@iso/schemasAndValidations/Template';
const initState = {
  isLoading: false,
  errorMessage: false,
  templateFiles: [],
  modalActive: false,
  templateFile: initialValues
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        templateFiles: payload.data,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        errorMessage: 'There is a loading problem'
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        templateFile: payload.data == null ? initState.templateFile : payload.data
      };
    case actions.TABLE_HANDLER:
      return {
        ...state,
        isState: payload.data
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
}
